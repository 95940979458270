import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
const GlobalStyle = createGlobalStyle`

  * {

  }

  body {
    margin: 0;
  }
`

const Container = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
`

export default function Layout({ children }) {
  return (
    <Container>
      <Helmet>
        <title>Horizon Blue Music</title>
        <description>Dutch producer and songwriter Horizon Blue is known for his tropical vibes, happy melodies and touching lyrics. After a childhood filled with music, Horizon Blue started producing his own songs at the age of 17. He worked on a lot of different projects with people all over the world before he was ready to start Horizon Blue and follow his dreams. Now with his first releases going over 1.400.000 streams on Spotify, and making big steps in 2020 to achieve his dreams, Horizon Blue is one to look out for!</description>
        <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap" rel="stylesheet"></link>
      </Helmet>
      <GlobalStyle />
      { children }
    </Container>
  )
}