import React from "react"
import Background from "../components/Background"
import Layout from "../components/Layout"
import LatestSong from "../components/LatestSong"
import styled from "styled-components"
import Logo from "../components/Logo"


const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Area = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
`

export default function Home() {
  return <Layout>
    <Background />
    <Container>
      <Area>
        <Logo />
      </Area>
        <LatestSong />
    </Container>

  </Layout>
}
