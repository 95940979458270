import React from 'react'
import styled from 'styled-components'


const Video = styled.video`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
`

export default () => {
    return <React.Fragment>
        <Video src="/background.mp4" muted autoPlay loop controls={false}></Video>
    </React.Fragment>
}