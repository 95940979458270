import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-web'
import anime from 'animejs'

const Heading = styled.h1`
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
  font-size: 48px;
  margin: 0;
  text-shadow: 4px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  text-align: center;
    margin-top: 16px;
    opacity: 0;


    @media only screen and (min-width: 768px) {
        font-size: 80px;

    }

`

const Animation = styled.div`
    width: 200px;
    height: 200px;

    @media only screen and (min-width: 768px) {
        width: 300px;
    height: 300px;

    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export  default () => {
    const animation = useRef()
    const title = useRef()

    useEffect(() => {
        const anim = Lottie.loadAnimation({
            wrapper: animation.current,
            animType: 'svg',
            loop: false,
            path: '/data.json',
            autoplay: false,
        })

        anim.addEventListener('data_ready', () => {

            setTimeout(() => {
                anim.play()
            }, 1000)

            anime({
                targets: animation.current,
                translateY: [200, 0],
                delay: 2500,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
    
            anime({
                targets: title.current,
                opacity: [0, 1],
                delay: 3500,
                duration: 1000,
                easing: 'easeOutQuad'
            })
        })
    
    }, [])



    return  <Container>
        
        <Animation ref={animation}></Animation>
        <Heading ref={title}>Horizon<br />Blue</Heading>
        
    </Container>
}